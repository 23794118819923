import React, {Component} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Product from "./components/Product";
import Basket from "./components/Basket";
import NavBar from './components/NavBar';
class App extends Component {
  render(){
    return (
      <div className="App">   
        <div className="container">
          <h1>E-commerce shopping cart application</h1>
          <hr/>
          <div className="row">
            <div className="col-md-12 p-0">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<NavBar />}>
                    <Route index element={<Product />} />
                    <Route path="basket/*" element={<Basket />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
