import util from '../util';
import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getProductsAsync } from '../productSlice'
import { addToCart } from "../cartSlice";
import Filter from "../components/Filter";

const Products = () => {
  const dispatch = useDispatch()
  const { filteredProducts, loading } = useSelector((state) => state.products)
  useEffect(() => {
    dispatch(getProductsAsync())
  }, [dispatch])

  const handleAddToCart = (product) => {
    dispatch(addToCart(product))
  }

  if (loading) return <p>Loading...</p>

  return (
    <div>
      <div id="products">
        <Filter/><br/>
        <div className='row g-0'>
          {filteredProducts.map((product) => (
            <div key={product.id} className='col-md-4 my-2'>
              <div className='text-center border border-primary border-2 rounded productBox mx-auto'>
                <a href={`#${product.id}`}>
                  <img src={`/products/${product.sku}_2.jpg`} alt={product.title}/>
                  <div>
                    <p>{product.title}</p>
                  </div>
                </a>
                <div>
                  <strong>Price: {util.formatCurrency(product.price)}</strong><br/>
                  <button className='btn btn-primary' onClick={ () => handleAddToCart(product) }>Add to cart</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Products




