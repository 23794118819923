import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { sortBy, sortP, sizeBy, sizeP } from '../productSlice';
import { filterBy } from '../productSlice';
const Filter = () => {
    const dispatch = useDispatch()
    const { list, filteredProducts } = useSelector((state) => state.products)

    const handleSortChange = (e) => {
        dispatch(sortBy(e.target.value))
        listProducts();
    }

    let s = [...list]
    const handleSizeChange = e => {
        dispatch(sizeBy(e.target.value))
        listProducts();
    }
    
    const listProducts = () => {
        if(sortP !== ''){
            dispatch(filterBy(s))
        }

        if(sizeP !== ''){
            dispatch(filterBy(s))  
        } 
        
        dispatch(filterBy(list))
    }
    
    return (
                <div id="filter" className="row">
                    <div className="col-md-4 row" id='filtered'>
                        {filteredProducts.length} products found
                    </div>
                    <div className="col-md-4">
                        <label>Order by
                            <select className="form-control" value={sortP} onChange={e => handleSortChange(e)}>
                                <option value="">Select</option>
                                <option value="lowestprice">Lowest to highest</option>
                                <option value="highestprice">Highest to lowest</option>
                            </select>
                        </label>
                    </div>
                    <div className="col-md-4">
                        <label> Filter Size
                            <select className="form-control" value={sizeP} onChange={e => handleSizeChange(e)}>
                                <option value="">ALL</option>
                                <option value="xs">XS</option>
                                <option value="s">S</option>
                                <option value="m">M</option>
                                <option value="l">L</option>
                                <option value="xl">XL</option>
                                <option value="xxl">XXL</option>
                            </select>
                        </label>
                    </div>
                </div>
    )
}

export default Filter




