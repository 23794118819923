import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cartItems : JSON.parse(localStorage.getItem('cartItems')) || [],
    cartTotalQuantity : 0,
    cartTotalAmount: 0
}
const cartSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		addToCart: (state, action) => {
            const itemIndex = state.cartItems.findIndex(
                item => item.id === action.payload.id  
            );
            
            if(itemIndex >= 0){
                state.cartItems[itemIndex].cartQuantity += 1
            } else {
                const tempProduct = {...action.payload, cartQuantity: 1}
                state.cartItems.push(tempProduct);
                localStorage.setItem('cartItems',JSON.stringify(state.cartItems))
            }      
		},
        decreaseCart(state, action) {
            const itemIndex = state.cartItems.findIndex(
                item => item.id === action.payload.id  
            );
            
            if(itemIndex >= 0){
                state.cartItems[itemIndex].cartQuantity -= 1
            } else {
                const tempProduct = {...action.payload, cartQuantity: 1}
                state.cartItems.push(tempProduct);
                localStorage.setItem('cartItems',JSON.stringify(state.cartItems))
            }      
        },
        removeFromCart(state, action) {
            state.cartItems.map((cartItem) => {
              if (cartItem.id === action.payload.id) {
                const nextCartItems = state.cartItems.filter(
                  (item) => item.id !== cartItem.id
                );
      
                state.cartItems = nextCartItems;
              }
              localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
              return state;
            });
        },
		deleteProductFromCart: (state, action) => {
			const nextCartItem =  state.cartItems.filter( x => x.id !== action.payload.id);

            state.cartItems = nextCartItem
            localStorage.setItem('cartItems',JSON.stringify(state.cartItems))
		},
        clearCart(state, action) {
            state.cartItems = [];
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
	}
});

export const { addToCart, decreaseCart, removeFromCart, clearCart,deleteProductFromCart } = cartSlice.actions
export default cartSlice.reducer;