import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap'
import '../App.css';
const NavBar = () => {
    return (
        <div>
            <Navbar bg="dark" expand="lg">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand className="custom-link">Nervesys SHOP</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-button"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/">
                            <Nav.Link className="custom-link">Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="basket/*">
                            <Nav.Link className="custom-link">Basket</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </div> 
    )
}
 
export default NavBar;